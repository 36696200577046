import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import comentIcon from "../../assets/comentIcon.svg";
import logoIvi from "../../assets/logo.svg";
import question from "../../assets/question.svg";
import ButtonComponent from "../../components/ButtonComponent";
import LeftHomeComponent from "../../components/leftHomeComponent/LeftHomeComponent";
import MainHomeComponent from "../../components/MainHomeComponent/MainHomeComponent";
import RightHomeComponent from "../../components/RightHomeComponent/RightHomeComponent";
import "./contact.css";
import { RedirectContext } from "../../context/redirect";
import { useInitAuth } from "../../hooks/useInitAuth";
import { useMutation } from "@tanstack/react-query";
import { changeStatusLead, getLead } from "../../services/onboarding";
import { GET_LEAD_EXIST } from "../../query/constants";
import { SessionContext } from "../../context/session";
import { getLocalStorage } from "../../utils/localStorage";
import LoadingFullPage from "../../components/Loading/LoadingFullPage/LoadingFullPage";
import PdfAlert from "../../assets/installation/pdf-alert.png";
import { ScreenError } from "../../components/screenError";
import { AxiosError } from "axios";
import { getImage } from "../../services/images";
import { ContactForm } from "./contactForm";
import { textsPath } from "../../services/texts";
import { ModalEmailExistente } from "./ModalEmailExistente";
import { ModalRef } from "../../utils/modal";
import { ModalTermosDeUso } from "../../components/modal/ModalTermsUso/ModelTermosDeUso";

export const Contact = () => {
  const navigate = useNavigate();
  const { getItemSession, setItemSession } = useContext(SessionContext);
  const { setContinueResgistration } = useContext(RedirectContext);
  const [statusLead, setStatusLead] = useState<boolean>(false);
  const [noEditEmail, setNoEditEmail] = useState<boolean>(false);
  const [continueEmail, setContinueEmail] = useState<boolean>(false);
  const [formData, setFormData] = useState({email: "", name: "", phone: ""});
  const [formValid, setFormValid] = useState(false);
  const [texts, setTexts] = useState({ description: "", title: "" })
  const [isPoliticaAceita, setIsPoliticaAceita] = useState(false);
  const { title, description } = texts;

  const { email, name, phone } = formData;
  const modalEmailExistenteRef = useRef<ModalRef | null>(null);
  const modalTermosDeUsoRef = useRef<ModalRef | null>(null);

  const openTermosDeUso = () => modalTermosDeUsoRef.current?.open();

  useEffect(() => {
    const storageContact = getItemSession("contact");
    const localStoragePath = getLocalStorage("path");
    const emailContinue = getLocalStorage("emailContinue");
    if (storageContact != null) {
      const contactModifyStorage = JSON.parse(storageContact);
      if (
        contactModifyStorage.email &&
        localStoragePath === "/confirm-your-data"
      ) {
        setNoEditEmail(true);
      }
      if (emailContinue) {
        setContinueEmail(true);
      }
    }
    setupTexts();
  }, []);

  const setupTexts = () => import(`./${textsPath}`).then(setTexts);

  const initAuth = useInitAuth();

  const existLead = useMutation({
    mutationFn: getLead,
    mutationKey: [GET_LEAD_EXIST],
    onSuccess: (data) => {
      const statusLead = data.status;

      if (statusLead === "PENDENTE") {
        // PENDENTE
        return modalEmailExistenteRef.current?.open();
      } else if (statusLead === "VALIDACAO_FATURA") {
        // VALIDACAO_FATURA
        return setStatusLead(true);
      }
    },
    onError(erro: AxiosError) {
      const status = erro.response?.data as string;
      if (status === "LEAD PENDENTE NOT_FOUND") {
        initAuth.mutate({ email, name });
      }
      const stausError = status.split(":")[1].trim();
      if (stausError === "VALIDACAO_FATURA") {
        return setStatusLead(true);
      }
    },
  });

  const putReproveLead = useMutation({
    mutationFn: changeStatusLead,
    mutationKey: ["put-reprove-lead"],
    onSuccess: (data) => {
      initAuth.mutate({ email, name });
    },
    onError(erro: AxiosError) {
      console.log(erro);
    },
  });
  //CONTINUAR CADASTRO:
  const handleContinueCadastro = () => {
    setContinueResgistration(true);
    setItemSession("emailContinue", email);
    initAuth.mutate({ email });
  };

  const handleReproveLead = () => {
    putReproveLead.mutate(email);
  };

  const handleBackButtonClick = () => {
    if (noEditEmail) return navigate("/confirm-your-data");
    navigate("/");
  };

  const creatContactLocalStorage = () => {
    const existContact = getItemSession("contact");
    if (existContact) {
      localStorage.removeItem(existContact);
    }

    const contactData = {
      name,
      email,
      phone,
      acceptTime: (new Date()).toString()
    };
    const contactDataString = JSON.stringify(contactData);
    setItemSession("contact", contactDataString);
  };

  const handleButtonClick = () => {
    creatContactLocalStorage();
    if (noEditEmail) {
      return navigate("/confirm-your-data");
    }
    if (continueEmail) {
      return navigate("/holder");
    }

    existLead.mutate(email);
  };

  return !statusLead ? (
    <MainHomeComponent>
      <LeftHomeComponent
        logoSrc={logoIvi}
        title={title}
        description={description}
        imgSrc={getImage("contact")}
      />
      <RightHomeComponent
        title={title}
        description={description}
        questionSrc={question}
        commentIconSrc={comentIcon}
        cardHomeClass="container-contact"
      >
        <div className="container-contact-form">
          <ContactForm 
            continueEmail={continueEmail}
            noEditEmail={noEditEmail}
            setFormData={setFormData}
            setFormValidation={setFormValid}
          />
          <div className="checkbox-container">
            <input type="checkbox" onChange={(e) => {console.log(e); setIsPoliticaAceita(e.target.checked)}}/>
            <div>Li e aceito os <span className="termos-de-uso-link" onClick={openTermosDeUso}>termos de uso e politica de privacidade</span></div>
          </div>
          {initAuth.isError && (
            <p className="error-mgs-contact">
              Por favor, verifique os dados digitados
            </p>
          )}
          <div className="container-button-contact">
            <ButtonComponent
              className="back-button"
              // className="btn-voltar-contact"
              onClick={handleBackButtonClick}
            >
              Voltar
            </ButtonComponent>

            <ButtonComponent
              className="continue-button"
              // className="btn-continue-contact"
              onClick={handleButtonClick}
              isDisabled={!formValid || !isPoliticaAceita}
            >
              Continuar
            </ButtonComponent>
          </div>
        </div>
        <ModalEmailExistente 
          handleContinueCadastro={handleContinueCadastro} 
          handleReproveLead={handleReproveLead} 
          ref={modalEmailExistenteRef}
        />
        <ModalTermosDeUso ref={modalTermosDeUsoRef} />

        {(existLead.isPending ||
          initAuth.isPending ||
          putReproveLead.isPending) && <LoadingFullPage />}
      </RightHomeComponent>
    </MainHomeComponent>
  ) : (
    <ScreenError
      icon={PdfAlert}
      alt="Pdf com uma exclamação"
      texto="Você possui uma fatura em análise, em breve daremos um retorno."
      title="Validação de Fatura"
    />
  );
};
